import {
  Box,
  chakra,
  Container,
  Image,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FaGithub, FaInstagram, FaLinkedin, FaStackOverflow, FaTwitter, FaYoutube } from 'react-icons/fa'
import { ReactNode, useEffect, useState } from 'react'
import { BuyMeCoffee } from './BuyMeCoffee'
import { useLocation } from 'react-router'

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export default function Footer() {
  let location = useLocation();
  const [isOnCheckPage, setIsOnCheckPage] = useState(false)

  useEffect(() => {
    setIsOnCheckPage(location.pathname.includes("/check/"))
  }, [location, setIsOnCheckPage])

  return (
    <Box 
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Text></Text>
        <Text>Made with &#127873; in Poland © 2021-2024 Let's be Santa!</Text>
        <Stack direction={'row'} spacing={6} align={'center'}>
          { isOnCheckPage ? <></> : <BuyMeCoffee/>}
          <SocialButton label={'GitHub'} href={'https://github.com/emlagowski'}>
            <FaGithub size={30}/>
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  )
}