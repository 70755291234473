import { Center, Heading } from "@chakra-ui/layout";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { toMainPage } from "../../routes";
import { Image } from '@chakra-ui/react'

export const LogoHeader = () => {
  const navigator = useNavigate();
  const returnToMainPage = useCallback(() => {
    navigator(toMainPage());
  }, [navigator])
  return (
    <Center flexDirection={"row"}>
      <Image height={14} src={'/apple-touch-icon.png'} alt="Secret Santa"/>
      <Heading m="6" onClick={returnToMainPage}>Let's be Santa!</Heading>
    </Center>
  );
}