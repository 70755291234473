import {
    Image,
} from '@chakra-ui/react'

export const BuyMeCoffee = () => {
    return (
        <a href="https://www.buymeacoffee.com/emlagowski" target="_blank">
            <Image
                height="50px"
                width="180px"
                src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png" alt="Buy Me A Coffee" />
        </a>
    );
}